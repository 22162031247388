@import url('https://fonts.googleapis.com/css?family=Cairo&display=swap');

body {
    margin: 0;
    font-family: "Cairo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

    .ant-form-inline .ant-form-item {
        display: block;
    }

.hide-cell {
  display: none;
}